export const SELECT_LOCATION = "Select Location"
export const PROFILE_ACTIONS = "/profile-actions"
export const REGISTER_ACTION = "/add-service"
export const MY_SERVICES = "/myservices"

export const HOME = "/"
export const LOCATIONS = [
    "Chandigarh",
    "Kharar",
    "Mohali",
    "Zirakpur",
    "Panchkula",
    "Shahpur",
    "Gaggal",
    "Gaggal Airport",
    "Dharmshala",
    "Tanda",
    "Palampur",
    "McLeod Ganj",
    "Dharmkot",
    "Kangra",
    "Bilaspur",
    "Mandi",
    "Bhuntar",
    "Bir",
    "Kasol",
    "Tosh Valley (Kasol)",
    "Dalhousie",
    "Kullu",
    "Manali",
    "Una",
    "Solan",
    "Shimla",
    "Pathankot",
    "Delhi",
    "Noida",
    "Gurugram",
    "Bengaluru",
    "Pune",
    "Hyderabad",
    "Surat",
    "Ahmedabad",
    "Faridabad",
    "Jammu",
    "Mumbai",
    "Ghaziabad",
    "Jaipur",
    "Jodhpur",
    "Baijnath (HP)",
    "Prayagraj (Allahabad)",
    "Sikkim",
    "Bhutan",
    "Darjeeling",
    "Shillong",
    "Indiranagar (Bengaluru)", 
    "Whitefield (Bengaluru)", 
    "Koramangala (Bengaluru)", 
    "HSR Layout (Bengaluru)", 
    "Jayanagar (Bengaluru)", 
    "Electronic City (Bengaluru)", 
    "Malleshwaram (Bengaluru)", 
    "Hebbal (Bengaluru)", 
    "Sarjapur Road (Bengaluru)", 
    "Banashankari (Bengaluru)", 
    "Yelahanka (Bengaluru)", 
    "Marathahalli (Bengaluru)", 
    "Basavanagudi (Bengaluru)", 
    "BTM Layout (Bengaluru)", 
    "Rajajinagar (Bengaluru)", 
    "Bellandur (Bengaluru)", 
    "Kalyan Nagar (Bengaluru)", 
    "Nagarbhavi (Bengaluru)", 
    "RT Nagar (Bengaluru)", 
    "Vasanth Nagar (Bengaluru)", 
    "MG Road (Bengaluru)", 
    "Hennur (Bengaluru)", 
    "Kanakapura Road (Bengaluru)", 
    "Bannerghatta Road (Bengaluru)", 
    "Peenya (Bengaluru)", 
    "JP Nagar (Bengaluru)", 
    "Ulsoor (Bengaluru)", 
    "Hoodi (Bengaluru)", 
    "Mahadevapura (Bengaluru)", 
    "Kadugodi (Bengaluru)", 
    "Kundalahalli (Bengaluru)", 
    "Domlur (Bengaluru)", 
    "Kaggadasapura (Bengaluru)", 
    "Ramamurthy Nagar (Bengaluru)", 
    "KR Puram (Bengaluru)",
    "Mysore (Mysuru)",
    "Belgaum (Belagavi) Karnataka"
]
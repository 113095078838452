import { ADVOCATES, AIR_CONDITIONER, ARCHITECTS, ASTROLOGIST, BAKERS, BAND_BAJA, BARS, BUNGEE_JUMPING, BUSES, CAFES, CARPENTER, CATERING, CA_CHARTERED_ACCOUNTANT, CCTV_CAMERAS, CHIMNEY, COMMERCIAL_LEASE, CONCRETE_MIXER, COOK, DANCE_CLUBS, DANCE_STUDIOS, DHOL, DISHWASHER, DJ_SOUND_SYSTEM, ELECTRICIAN, EVENT_CATERERS_AND_FOOD_SERVICES, EVENT_TRANSPORTATION_AND_LOGISTICS, EVENT_VENUES_OR_EVENT_SPACES, FLAT_HOMES, GAS_STOVE, GEYSER, GLASS_FITTINGS, GREEN_LEAF_PLATES, GROOMS_CAR, GYMS, GYPSUM_CEILING, HIMACHALI_DHAM, HOME_CLEANING, JCB_BACKHOE, MACHINERY_FOR_CONSTRUCTION, MARBLE_INSTALLER, MARRIAGE_BANQUET_HALL, MICROWAVE, MODULAR_KITCHEN, MUSICAL_BANDS, PAINTER, PANDIT_FOR_PUJA, PARAGLIDING, PARTY_HALL, PAYING_GUEST, PHOTOGRAPHER, PHOTOSTAT, PICK_UP_VANS, PILATES_STUDIOS, PLUMBER, PRINTER_AND_INK_REFILL, PROPERTY_DEALERS, PROPERTY_RENTAL, PVC_PANELLING, RAJ_MISTRI, REFRIGERATOR, RENTAL_BROKERS, RESTAURANTS, RETAIL_SHOPS, RO_PURIFIER, SHUTTERING, SINGLE_ROOM, SOFA_CARPET_CLEANING, SPA_CENTERS, SWIMMING_POOLS, TAXIS, TELEVISION, TENT, TILE_INSTALLER, TOW_TRUCK, TRUCKS, UPVC_ALUMINIUM_DOOR_WINDOW, VASTU, VOLVO_BUS, WALLPAPER_INSTALLATION, WASHING_MACHINE, WATER_PROOFING, WATER_TANKER, WATER_TANK_CLEANING, WELDER_METAL_FABRICATOR, WIFI, WOODEN_DOOR_FITTINGS, YOGA_STUDIOS } from "../constants/DB/ServiceNames";

const goToServiceList = [
  {
    "sectorName": "Electrical Plumbing and Appliance Services",
    "s3PathName": "electrical-plumbing-appliance-services",
    "services": [
      {
        "name": "Electrician",
        "image": "/Electrician.jpg",
        "link": "/electrician",
        "comingSoon": false,
        "serviceName": ELECTRICIAN
      },
      {
        "name": "Plumber",
        "image": "/Plumber.jpg",
        "link": "/plumber",
        "comingSoon": false,
        "serviceName": PLUMBER
      },
      {
        "name": "Wifi",
        "image": "/WifiLan.jpeg",
        "link": "/wifi",
        "comingSoon": false,
        "serviceName": WIFI
      },
      {
        "name": "RO Purifier",
        "image": "/ROPurifier.jpeg",
        "link": "/ro",
        "comingSoon": false,
        "serviceName": RO_PURIFIER
      },
      {
        "name": "AC",
        "image": "/AC.jpeg",
        "link": "/ac",
        "comingSoon": false,
        "serviceName": AIR_CONDITIONER
      },
      {
        "name": "Refrigerator",
        "image": "/Refrigerator.jpeg",
        "link": "/refrigerator",
        "comingSoon": false,
        "serviceName": REFRIGERATOR
      },
      {
        "name": "CCTV",
        "image": "/CCTV.jpeg",
        "link": "/cctv",
        "comingSoon": false,
        "serviceName": CCTV_CAMERAS
      },
      {
        "name": "TV",
        "image": "/TV.jpeg",
        "link": "/tv",
        "comingSoon": false,
        "serviceName": TELEVISION
      },
      {
        "name": "Washing Machine",
        "image": "/WashingMachine.jpeg",
        "link": "/washing-machine",
        "comingSoon": false,
        "serviceName": WASHING_MACHINE
      },
      {
        "name": "Chimney",
        "image": "/Electrician.jpg",
        "link": "/chimney",
        "comingSoon": false,
        "serviceName": CHIMNEY
      },
      {
        "name": "Geyser",
        "image": "/Geyser.jpeg",
        "link": "/geyser",
        "comingSoon": false,
        "serviceName": GEYSER
      },
      {
        "name": "Gas Stove",
        "image": "/GasStove.jpeg",
        "link": "/gas-stove",
        "comingSoon": false,
        "serviceName": GAS_STOVE
      },
      {
        "name": "Dishwasher",
        "image": "/Dishwasher.jpeg",
        "link": "/dishwasher",
        "comingSoon": false,
        "serviceName": DISHWASHER
      },
      {
        "name": "Microwave",
        "image": "/Microwave.jpeg",
        "link": "/microwave",
        "comingSoon": false,
        "serviceName": MICROWAVE
      },
      {
        "name": "Printer and Ink Refill",
        "image": "/PrinterInk.jpeg",
        "link": "/printer-ink",
        "comingSoon": false,
        "serviceName": PRINTER_AND_INK_REFILL
      },
      {
        "name": "Photostat",
        "image": "/Photostat.jpeg",
        "link": "/photostat",
        "comingSoon": false,
        "serviceName": PHOTOSTAT
      }
    ]
  },
  {
    "sectorName": "Rentals",
    "s3PathName": "rentals",
    "services": [
      {
        "name": "PG",
        "image": "/PG.jpeg",
        "link": "/pg",
        "comingSoon": false,
        "serviceName": PROPERTY_RENTAL,
        "choiceName": PAYING_GUEST,
        "cardType": "image"
      },
      {
        "name": "Single Room",
        "image": "/SingleRoom.jpeg",
        "link": "/single-room",
        "comingSoon": false,
        "serviceName": PROPERTY_RENTAL,
        "choiceName": SINGLE_ROOM,
        "cardType": "image"
      },
      {
        "name": "Flats/Homes",
        "image": "/Flats.jpeg",
        "link": "/flat-homes",
        "comingSoon": false,
        "serviceName": PROPERTY_RENTAL,
        "choiceName": FLAT_HOMES,
        "cardType": "image"
      },
      {
        "name": "Retail-Shops",
        "image": "/Shops.jpeg",
        "link": "/retail-shops",
        "comingSoon": false,
        "serviceName": COMMERCIAL_LEASE,
        "choiceName": RETAIL_SHOPS,
        "cardType": "image"
      }
    ]
  },
  {
    "sectorName": "Transportation",
    "s3PathName": "transportation",
    "services": [
      {
        "name": "Taxis",
        "image": "/Taxis.jpeg",
        "link": "/taxis",
        "comingSoon": false,
        "serviceName": TAXIS,
        "cardType": "image"
      },
      {
        "name": "Pick Up Vans",
        "image": "/PickUpVans.jpeg",
        "link": "/pickup-vans",
        "comingSoon": false,
        "serviceName": PICK_UP_VANS,
        "cardType": "image"
      },
      {
        "name": "Volvo Bus",
        "image": "/VolvoBus.jpeg",
        "link": "/volvo-bus",
        "comingSoon": false,
        "serviceName": BUSES,
        "choiceName": VOLVO_BUS,
        "cardType": "image"
      },
      {
        "name": "Water Tanker",
        "image": "/WaterTanker.jpeg",
        "link": "/water-tanker",
        "comingSoon": false,
        "serviceName": WATER_TANKER,
        "cardType": "image"
      },
      {
        "name": "Tow Trucks",
        "image": "/TowTruck.jpeg",
        "link": "/tow-truck",
        "comingSoon": false,
        "serviceName": TRUCKS,
        "choiceName": TOW_TRUCK,
        "cardType": "image"
      }
    ]
  },
  {
    "sectorName": "Adventure activities",
    "s3PathName": "adventure-activities",
    "services": [
      {
        "name": "Paragliding",
        "image": "/Paragliding.jpeg",
        "link": "/paragliding",
        "comingSoon": false,
        "serviceName": PARAGLIDING,
      },
      {
        "name": "Bungee Jumping",
        "image": "/BungeeJumping.jpeg",
        "link": "/bungee-jumping",
        "comingSoon": false,
        "serviceName": BUNGEE_JUMPING
      }
    ]
  },
  {
    "sectorName": "Construction, Home and Decors and Architects",
    "s3PathName": "architects-construction-homedecors",
    "services": [
      {
        "name": "Architects",
        "image": "/Architect.jpeg",
        "link": "/architects",
        "comingSoon": false,
        "serviceName": ARCHITECTS
      },
      {
        "name": "Modular Kitchen",
        "image": "/ModularKitchen.jpeg",
        "link": "/modular-kitchen",
        "comingSoon": false,
        "serviceName": MODULAR_KITCHEN
      },
      {
        "name": "Wooden Door",
        "image": "/WoodenDoorFitting.jpeg",
        "link": "/modular-kitchen",
        "comingSoon": false,
        "serviceName": WOODEN_DOOR_FITTINGS
      },
      {
        "name": "PVC Panelling",
        "image": "/PVCPanelling.jpeg",
        "link": "/modular-kitchen",
        "comingSoon": false,
        "serviceName": PVC_PANELLING
      },
      {
        "name": "Gypsem Ceiling",
        "image": "/GypsemCeiling.jpg",
        "link": "/modular-kitchen",
        "comingSoon": false,
        "serviceName": GYPSUM_CEILING
      },
      {
        "name": "WallPaper",
        "image": "/WallPaperInstallation.jpeg",
        "link": "/modular-kitchen",
        "comingSoon": false,
        "serviceName": WALLPAPER_INSTALLATION
      },
      {
        "name": "Glass Fittings",
        "image": "/GlassFittings.jpeg",
        "link": "/modular-kitchen",
        "comingSoon": false,
        "serviceName": GLASS_FITTINGS
      },
      {
        "name": "Painter",
        "image": "/Painter.jpeg",
        "link": "/painter",
        "comingSoon": false,
        "serviceName": PAINTER
      },
      {
        "name": "Carpenters",
        "image": "/Carpanter.jpeg",
        "link": "/carpenter",
        "comingSoon": false,
        "serviceName": CARPENTER
      },
      {
        "name": "Welder",
        "image": "/Welder.jpeg",
        "link": "/metal-fabricator",
        "comingSoon": false,
        "serviceName": WELDER_METAL_FABRICATOR
      },
      {
        "name": "UPVC Aluminium",
        "image": "/UPVCAluminiumWindow.jpeg",
        "link": "/upvc-aluminium",
        "comingSoon": false,
        "serviceName": UPVC_ALUMINIUM_DOOR_WINDOW
      },
      {
        "name": "Raj Mistri",
        "image": "/RajMistri.jpeg",
        "link": "/construction-worker",
        "comingSoon": false,
        "serviceName": RAJ_MISTRI
      },
      {
        "name": "Electrician",
        "image": "/Electrician.jpg",
        "link": "/electrician",
        "comingSoon": false,
        "serviceName": ELECTRICIAN
      },
      {
        "name": "Plumber",
        "image": "/Plumber.jpg",
        "link": "/plumber",
        "comingSoon": false,
        "serviceName": PLUMBER
      },
      {
        "name": "Tile Installers",
        "image": "/TileInstaller.jpeg",
        "link": "/tile-installers",
        "comingSoon": false,
        "serviceName": TILE_INSTALLER
      },
      {
        "name": "Marble Installers",
        "image": "/MarbleInstaller.jpeg",
        "link": "/marble-installers",
        "comingSoon": false,
        "serviceName": MARBLE_INSTALLER
      },
      {
        "name": "JCB",
        "image": "/JCB.jpeg",
        "link": "/jcb",
        "comingSoon": false,
        "serviceName": MACHINERY_FOR_CONSTRUCTION,
        "choiceName": JCB_BACKHOE
      },
      {
        "name": "Concrete Mixer",
        "image": "/ConcreteMixer.jpeg",
        "link": "/concrete-mixer",
        "comingSoon": false,
        "serviceName": MACHINERY_FOR_CONSTRUCTION,
        "choiceName": CONCRETE_MIXER
      },
      {
        "name": "Shuttering",
        "image": "/Shuttering.jpeg",
        "link": "/shuttering",
        "comingSoon": false,
        "serviceName": SHUTTERING
      },
      {
        "name": "WaterProofing",
        "image": "/WaterProofing1.jpg",
        "link": "/water-proofing",
        "comingSoon": false,
        "serviceName": WATER_PROOFING
      }
    ]
  },
  {
    "sectorName": "Wedding and Event Management",
    "s3PathName": "wedding-event-management",
    "services": [
      {
        "name": "Photographer",
        "image": "/Photographer.jpeg",
        "link": "/photographer",
        "comingSoon": false,
        "serviceName": PHOTOGRAPHER
      },
      {
        "name": "Catering",
        "image": "/Catering.jpeg",
        "link": "/catering",
        "comingSoon": false,
        "serviceName": EVENT_CATERERS_AND_FOOD_SERVICES,
        "choiceName": CATERING
      },
      {
        "name": "Party Hall",
        "image": "/PartyHall.jpeg",
        "link": "/party-hall",
        "comingSoon": false,
        "serviceName": EVENT_VENUES_OR_EVENT_SPACES,
        "choiceName": PARTY_HALL,
        "cardType": "image"
      },
      {
        "name": "Marriage Palace",
        "image": "/MarriageHall.jpeg",
        "link": "/marriage-banquet-hall",
        "comingSoon": false,
        "serviceName": EVENT_VENUES_OR_EVENT_SPACES,
        "choiceName": MARRIAGE_BANQUET_HALL,
        "cardType": "image"
      },
      {
        "name": "Dham Boti",
        "image": "/HimachaliDham.jpeg",
        "link": "/himachali-dham-cook",
        "comingSoon": false,
        "serviceName": COOK,
        "choiceName": HIMACHALI_DHAM
      },
      {
        "name": "Green Leaf Plates",
        "image": "/GreenLeafPlates.jpeg",
        "link": "/green-leaf-plates",
        "comingSoon": false,
        "serviceName": GREEN_LEAF_PLATES
      },
      {
        "name": "Tent",
        "image": "/Tent.jpeg",
        "link": "/tent",
        "comingSoon": false,
        "serviceName": TENT
      },
      {
        "name": "Band Baja",
        "image": "/BandBaja.jpeg",
        "link": "/band-baja",
        "comingSoon": false,
        "serviceName": MUSICAL_BANDS,
        "choiceName": BAND_BAJA
      },
      {
        "name": "Dhol",
        "image": "/Dhol.jpeg",
        "link": "/dhol",
        "comingSoon": false,
        "serviceName": MUSICAL_BANDS,
        "choiceName": DHOL
      },
      {
        "name": "DJ Sound System",
        "image": "/DJ.jpeg",
        "link": "/dj-sound-system",
        "comingSoon": false,
        "serviceName": DJ_SOUND_SYSTEM
      },
      {
        "name": "Groom's Car",
        "image": "/GroomCar.jpeg",
        "link": "/groom-car",
        "comingSoon": false,
        "serviceName": EVENT_TRANSPORTATION_AND_LOGISTICS,
        "choiceName": GROOMS_CAR,
      }
    ]
  },
  {
    "sectorName": "Cleaning Services",
    "s3PathName": "cleaning-services",
    "services": [
      {
        "name": "Sofa and Carpet",
        "image": "/Sofa1.jpg",
        "link": "/sofa-carpet",
        "comingSoon": false,
        "serviceName": SOFA_CARPET_CLEANING,
      },
      {
        "name": "Water Tank Cleaning",
        "image": "/WaterTankCleaning1.jpg",
        "link": "/water-tank-cleaning",
        "comingSoon": false,
        "serviceName": WATER_TANK_CLEANING,
      },
      {
        "name": "Home Cleaning",
        "image": "/HomeCleaning.jpg",
        "link": "/home-cleaning",
        "comingSoon": false,
        "serviceName": HOME_CLEANING,
      }
    ]
  },
  {
    "sectorName": "Legal, Compliance and Accounting",
    "s3PathName": "legal-compliance-accounting",
    "services": [
      {
        "name": "CA",
        "image": "/CA.jpeg",
        "link": "/ca",
        "comingSoon": false,
        "serviceName": CA_CHARTERED_ACCOUNTANT,
      },
      {
        "name": "Advocates",
        "image": "/Advocates.png",
        "link": "/advocates",
        "comingSoon": false,
        "serviceName": ADVOCATES
      }
    ]
  },
  {
    "sectorName": "Food Dining Venues",
    "s3PathName": "dining-venues",
    "services": [
      {
        "name": "Cafes",
        "image": "/Cafes.jpeg",
        "link": "/ca",
        "comingSoon": false,
        "serviceName": CAFES,
        "cardType": "image"
      },
      {
        "name": "Restaurants",
        "image": "/Restaurants.jpeg",
        "link": "/advocates",
        "comingSoon": false,
        "serviceName": RESTAURANTS,
        "cardType": "image"
      },
      {
        "name": "Bakers",
        "image": "/Bakers.jpg",
        "link": "/advocates",
        "comingSoon": false,
        "serviceName": BAKERS,
        "cardType": "image"
      }
    ]
  },
  {
    "sectorName": "Fitness and Fun Activities",
    "s3PathName": "fitness-fun-activities",
    "services": [
      {
        "name": "Dance Studios",
        "image": "/DanceStudio.jpg",
        "link": "/dance-studios",
        "comingSoon": false,
        "serviceName": DANCE_STUDIOS,
        "cardType": "image"
      },
      {
        "name": "Swimming Pools",
        "image": "/SwimmingPool.jpg",
        "link": "/swimming-pools",
        "comingSoon": false,
        "serviceName": SWIMMING_POOLS,
        "cardType": "image"
      },
      // {
      //   "name": "Pilates Studios",
      //   "image": "/PG.jpeg",
      //   "link": "/pilates-studios",
      //   "comingSoon": false,
      //   "serviceName": PILATES_STUDIOS,
      //   "cardType": "image"
      // },
      {
        "name": "Gyms",
        "image": "/Gyms.jpg",
        "link": "/gyms",
        "comingSoon": false,
        "serviceName": GYMS,
        "cardType": "image"
      },
      {
        "name": "Yoga Studios",
        "image": "/YogaStudios.jpg",
        "link": "/yoga-studios",
        "comingSoon": false,
        "serviceName": YOGA_STUDIOS,
        "cardType": "image"
      },
      {
        "name": "DANCE_CLUBS",
        "image": "/DanceClub.jpg",
        "link": "/dance-clubs",
        "comingSoon": true,
        "serviceName": DANCE_CLUBS,
        "cardType": "image"
      },
      {
        "name": "Spa Centers",
        "image": "/Spa.jpg",
        "link": "/spa-centers",
        "comingSoon": true,
        "serviceName": SPA_CENTERS,
        "cardType": "image"
      },
      // {
      //   "name": "Bars",
      //   "image": "/Shops.jpeg",
      //   "link": "/bars",
      //   "comingSoon": true,
      //   "serviceName": BARS,
      //   "cardType": "image"
      // }
    ]
  },
  {
    "sectorName": "Real Estate Consultants",
    "s3PathName": "real-estate-consultants",
    "services": [
      {
        "name": "Rental Brokers",
        "image": "/Brokers.jpeg",
        "link": "/ca",
        "comingSoon": false,
        "serviceName": RENTAL_BROKERS,
      },
      {
        "name": "Property Dealers",
        "image": "/PropertyDealers.jpeg",
        "link": "/advocates",
        "comingSoon": false,
        "serviceName": PROPERTY_DEALERS
      }
    ]
  },
  {
    "sectorName": "Pandit & Astrologer Services",
    "s3PathName": "pandit-astrologer-services",
    "services": [
      {
        "name": "Pandit for Puja",
        "image": "/PanditPuja.jpg",
        "link": "/pandit-for-puja",
        "comingSoon": false,
        "serviceName": PANDIT_FOR_PUJA
      },
      {
        "name": "Astrology Consultation",
        "image": "/AstrologyConsultation.jpg",
        "link": "/astrology-consultation",
        "comingSoon": false,
        "serviceName": ASTROLOGIST
      },
      {
        "name": "Vastu Consultation",
        "image": "/VastuConsultation.jpg",
        "link": "/vastu-consultation",
        "comingSoon": false,
        "serviceName": VASTU
      }
    ]
  }
]

export default goToServiceList